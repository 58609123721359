<template>
  <main class="container mx-auto" @click="checkParentModal">
    <div class="flex flex-col justify-center items-center mx-auto my-5 py-4">
      <h1 class="rounded text-3xl text-center text-bold text-white bg-black p-4 mb-3">
        {{ $t("YOU WANT TO SELL YOUR MACHINE ?") }}
      </h1>
      <p class="text-center p-2">
        {{ $t("We may be interested, please give us some informations.") }}
      </p>
    </div>
    <div class="bg-gray-100 shadow-lg hover:shadow-2xl rounded mb-10">
      <div class="px-6 py-6">
        <div enctype="multipart/form-data" class="mx-2 my-2 md:grid md:grid-cols-2 sm:flex sm:flex-wrap">
          <div class="mx-2 my-5">
            <h2 class="mb-6 text-gray-500 text-3xl">
              {{ $t("About your machine") }}
            </h2>

            <div class="mb-6 flex flex-col justify-center">
              <label for="model" class="">{{ $t("inputModel") }}</label>
              <input
                v-model="model"
                type="text"
                id="model"
                name="model"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="brand">{{ $t("inputBrand") }}</label>
              <input
                v-model="brand"
                type="text"
                id="brand"
                name="brand"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="year">{{ $t("InputYear") }}</label>
              <input
                v-model.number="year"
                type="number"
                id="year"
                name="year"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="price">{{ $t("InputPrice") }}</label>
              <input
                v-model.number="price"
                type="number"
                id="price"
                name="price"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <!-- <div class="mb-6 flex flex-col justify-center">
              <label for="brand">{{ $t("Brand") }}</label>
              <input
                v-model="brand"
                type="text"
                id="brand"
                name="brand"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div> -->
            <div class="mb-6 flex flex-col justify-center">
              <label for="file">{{ $t("File") }}</label>
              <input
                @change="handleFileUpload($event)"
                type="file"
                id="file"
                name="files"
                accept=".pdf, .docx, .xls, .doc, .xlsx, .txt, .csv, .pptx, .zip"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                multiple
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="image">{{ $t("Image") }}</label>
              <input
                @change="handleImageUpload($event)"
                type="file"
                id="image"
                name="images"
                accept="image/*"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                multiple
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="description">{{ $t("Description") }}</label>
              <textarea
                v-model="description"
                type="text"
                id="description"
                name="description"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
          </div>
          <div class="mx-2 my-5">
            <h2 class="mb-6 text-gray-500 text-3xl">{{ $t("About you") }}</h2>
            <div class="mb-6 flex flex-col justify-center">
              <label for="firstname">{{ $t("Firstname") }}</label>
              <input
                v-model="firstname"
                type="text"
                id="firstname"
                name="firstname"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="lastname">{{ $t("Lastname") }}</label>
              <input
                v-model="lastname"
                type="text"
                id="lastname"
                name="lastname"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="company">{{ $t("InputCompany") }}</label>
              <input
                v-model="company"
                type="text"
                id="company"
                name="company"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <!-- START SECTOR FILTER -->
            <div class="md:w-5/6" id="modal">
              <div class="">
                <label for="company">{{ $t("Activity") }}</label>
                <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
                  <input
                    v-model="inputActivity"
                    @focus="getActivity"
                    @input="getActivity"
                    :placeholder="
                      this.checkedActivities
                        .map((item) => {
                          return item.attributes[$i18n.locale == 'fr' ? 'descFR' : 'descEN'].trim();
                        })
                        .join(', ')
                    "
                    class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                  />
                  <div>
                    <button @click="!isActivityOpen ? getActivity() : (isActivityOpen = false)" id="toggle" class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none">
                      <svg v-if="isActivityOpen" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                      <svg
                        v-if="!isActivityOpen"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="isActivityOpen" class="shadow top-100 z-40 lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
                <div class="flex flex-col w-full">
                  <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100" style="" v-for="(activity, index) in activitys" :key="'activity' + index">
                    <div class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                      <div class="w-full items-center flex">
                        <input type="checkbox" class="mr-4" :id="activity.id" :value="activity" v-model="checkedActivities" />
                        <label :for="activity.id">{{ $i18n.locale == "fr" ? activity.attributes.descFR : activity.attributes.descEN.trim() ? activity.attributes.descEN : activity.attributes.descFR }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END SECTOR FILTER -->
            <div class="mb-6 flex flex-col justify-center">
              <label for="adress">{{ $t("Adresse") }}</label>
              <input
                v-model="adress"
                type="text"
                id="adress"
                name="adress"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="country">{{ $t("Pays") }}</label>
              <input
                v-model="country"
                type="text"
                id="country"
                name="country"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="email">{{ $t("Email") }}</label>
              <input
                v-model="email"
                type="text"
                id="email"
                name="email"
                class="form-control block md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <span>{{ $t("* Required fields") }}</span>
            <div class="mt-10">
              <button @click.prevent="handle" class="px-6 py-2 border-2 border-green-700 text-green-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                {{ $t("Send") }}
              </button>
            </div>
          </div>
        </div>
        <div class="">
          <!-- <p>{{ formSellData.model }}</p>
          <p>{{ formSellData.brand }}</p>
          <p>{{ formSellData.year }}</p>
          <p>{{ formSellData.price }}</p>
          <p>{{ formSellData.file }}</p>
          <p>{{ formSellData.image }}</p>
          <p>{{ formSellData.description }}</p>
          <p>{{ formSellData.firstname }}</p>
          <p>{{ formSellData.lastname }}</p>
          <p>{{ formSellData.company }}</p>
          <p>{{ formSellData.email }}</p> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import qs from "qs";
const formData = new FormData();
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
export default {
  name: "formulaire-shell",
  data() {
    return {
      model: "",
      brand: "",
      year: "",
      price: "",
      description: "",
      firstname: "",
      lastname: "",
      company: "",
      email: "",
      images: [],
      files: [],
      country: "",
      adress: "",
      inputActivity: "",
      inputCode: "",
      isActivityOpen: false,
      activitys: [],
      idActivity: "",
      checkedActivities: [],
    };
  },
  mounted() {
    if (localStorage.lastname) {
      this.lastname = localStorage.lastname;
    }
    if (localStorage.firstname) {
      this.firstname = localStorage.firstname;
    }
    if (localStorage.email) {
      this.email = localStorage.email;
    }
    if (localStorage.company) {
      this.company = localStorage.company;
    }

    const getQuery = this.$route.query;
    this.model = getQuery?.model;
    this.brand = getQuery?.brand;
    this.year = getQuery?.year;
    console.log(this.model);
    console.log(this.brand);
    console.log(this.year);
  },

  methods: {
    handleFileUpload(e) {
      this.files = e.target.files;
    },
    checkParentModal(event) {
      let parent = event.target.parentElement;
      let found = false;
      while (parent) {
        if (parent.id === "modal") {
          found = true;
        }
        parent = parent.parentElement;
      }
      if (!found) {
        (this.isActivityOpen = false), (this.isBrandOpen = false), (this.isSubCategoryOpen = false);
      }
    },
    handleImageUpload(e) {
      // console.log([...e.target.images]);
      this.images = e.target.files;
    },
    async getActivity() {
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters: {
          $and: [this.$i18n.locale == "fr" ? { descFR: { $contains: this.inputActivity.trim() } } : { descEN: { $contains: this.inputActivity.trim() } }, { code: { $contains: "00" } }],
        },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`);
        const uniqueActivity = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.descFR === value.attributes.descFR) === index;
        });
        this.activitys = uniqueActivity;
        this.isActivityOpen = true;
      } catch (error) {
        this.error = error;
      }
    },
    async handle() {
      const data = {
        model: this.model,
        brand: this.brand,
        year: this.year,
        price: this.price,
        description: this.description,
        firstname: this.firstname,
        lastname: this.lastname,
        company: this.company,
        email: this.email,
        country: this.country,
        adresss: this.adress,
      };
      // ajout des fichier au formData
      formData.append("data", JSON.stringify(data));
      formData.append(
        "files.file",
        [...this.files].forEach((f) => {
          formData.append("files.file", f, f.name);
        })
      );
      formData.append(
        "files.image",
        [...this.images].forEach((i) => {
          formData.append("files.image", i, i.name);
        })
      );

      // console.log(this.formSellData.image);
      // Sauvegarde des données utilisateur dans le localStorage
      localStorage.lastname = this.lastname;
      localStorage.firstname = this.firstname;
      localStorage.email = this.email;
      localStorage.company = this.company;
      console.log("Saved data");

      const mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.description) {
        alert(this.$t("Enter a machine description"));
        return false;
      } else if (!this.firstname) {
        alert(this.$t("Enter a firstname"));
        return false;
      } else if (!this.lastname) {
        alert(this.$t("Enter a lastname"));
        return false;
      } else if (!this.email) {
        alert(this.$t("Enter a email"));
        return false;
      } else if (!this.adress) {
        alert(this.$t("Enter an adress"));
        return false;
      } else if (!this.country) {
        alert(this.$t("Enter a country"));
        return false;
      } else if (this.email.match(mailFormat)) {
        // requete d'envoi du formulaire

        // console.log(this.checkedActivities.map((activity) => {
        //     return `<p>${activity.attributes[this.$i18n.locale == "fr" ? 'descFR' : 'descEN'].trim()}</p>`;
        //   }));

        const text = `
      <div>
        <h1>Informations client</h1>
        <p>Nom: ${this.lastname}</p>
        <p>Prénom: ${this.firstname}</p>
        <p>Nom de l'entreprise: ${this.company}</p>
        <p>Adresse: ${this.adress}</p>
        <p>Pays: ${this.country}</p>
        <p>E-mail: ${this.email}</p>
        <br>
        <br>
        <h1>Informations machine</h1>
        <p>${this.description}</p>
        <p>${this.price}</p>
        <p>${this.year}</p>
        <p>${this.brand}</p>
        <p>${this.model}</p>
        ${this.checkedActivities
          .map((activity) => {
            return `<p>${activity.attributes[this.$i18n.locale == "fr" ? "descFR" : "descEN"].trim()}</p>`;
          })
          .join("")}
      </div>
      `;

        // model: ${this.model},
        //   brand: ${this.brand},
        //   year: ${this.year},
        //   price: ${this.price},
        //   description: ${this.description},
        const attachments = [];
        for (let i = 0; i < this.files.length; i++) {
          const element = this.files[i];
          attachments.push({
            filename: element.name,
            path: await toBase64(element),
            encoding: "base64",
          });
        }
        for (let i = 0; i < this.images.length; i++) {
          const element = this.images[i];
          attachments.push({
            filename: element.name,
            path: await toBase64(element),
            encoding: "base64",
          });
        }
        const data = {
          to: "contact@jacquart.com",
          from: "development@atelier.ovh",
          subject: "Machine à vendre",
          text: text,
          attachments: attachments,
        };
        try {
          // const response = await axios.post(`${process.env.VUE_APP_STRAPI_API_URL}/api/email`, data);
          const response = await axios.post(`${process.env.VUE_APP_STRAPI_API_URL}/api/email`, data);
          if (response) {
            alert(this.$t("send-success"));
            window.location.href = "/";
          }
        } catch (error) {
          alert(this.$t("Something went wrong, please try again later"));
          console.log(error.response);
        }
        // if (response.status == 200) {
        //         alert(this.$t("Form send successfully"));
        //         return true;
        // }else{
        //   alert(this.$t("Something went wrong, please try again later"));
        // }
        // const datas = await response.json();
        // console.log(datas);
        // console.log(response);
      } else {
        alert(this.$t("Enter a valid email"));
        return false;
      }
      // console.log(formData);

      // console.log(e.target.image);
      // const formSellData = new FormData();
      // formSellData.append("data", JSON.stringify(data));
      // // formSellData.append("files.file", e.target.file.files[0], e.target.file.files[0].name);
      // // formSellData.append("files.image", [...e.target.image.files].forEach((i) => { formSellData.append("files.image", i, i.name)}));
      // const response = await fetch(`${process.env.VUE_APP_STRAPI_API_URL}/api/sells`, {
      //   method: "POST",
      //   body: formSellData
      //     // model: this.model,
      //     // brand: this.brand,
      //     // year: this.year,
      //     // price: this.price,
      //     // description: this.description,
      //     // firstname: this.firstname,
      //     // lastname: this.lastname,
      //     // company: this.company,
      //     // email: this.email,
      //   });
      //   const datas = await response.json();
      //   console.log(datas);
    },
    // async submitForm() {
    //   const res = await fetch("`${process.env.VUE_APP_STRAPI_API_URL}/api/sells`", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       forthis.formSellData
    //       // model: this.model,
    //       // brand: this.brand,
    //       // year: this.year,
    //       // price: this.price,
    //       // description: this.description,
    //       // firstname: this.firstname,
    //       // lastname: this.lastname,
    //       // company: this.company,
    //       // email: this.email,
    //     }),
    //   });
    // },
  },
};
</script>
