<template>
  <main class="container mx-auto" @click="checkParentModal">
    <div class="flex flex-col justify-center items-center mx-auto my-5 py-4">
      <h1 class="rounded text-3xl text-center text-bold text-white bg-black p-4 mb-3">
        {{ $t("Make your choice") }}
      </h1>
    </div>
    <div class="mb-10">
      <div class="px-6 py-6">
        <div class="mx-2 my-2 flex flex-col items-center justify-center">
          <div class="bg-gray-100 shadow-lg hover:shadow-2xl rounded px-10 py-10 relative w-4/5 sm:w-3/4 lg:w-1/2">
            <!-- START SECTOR FILTER -->
            <div class="px-1" id="modal">
              <div class="">
                <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
                  <input :placeholder="$t('Activity') + '*'" v-model="inputActivity" @focus="getActivity" @input="getActivity" class="p-1 px-2 appearance-none outline-none w-full text-gray-800" />
                  <div>
                    <button @click="!isActivityOpen ? getActivity() : (isActivityOpen = false)" id="toggle" class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none">
                      <svg v-if="isActivityOpen" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                      <svg
                        v-if="!isActivityOpen"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="isActivityOpen" class="shadow top-100 z-40 w-full lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
                <div class="flex flex-col w-full">
                  <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100" style="" v-for="(activity, index) in activitys" :key="'activity' + index">
                    <div class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                      <div class="w-full items-center flex">
                        <input type="checkbox" class="mr-4" :id="activity.id" :value="activity" v-model="checkedActivities" />
                        <label :for="activity.id">{{ $i18n.locale == "fr" ? activity.attributes.descFR : activity.attributes.descEN.trim() ? activity.attributes.descEN : activity.attributes.descFR }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="checkedActivities.length > 0">
              <span>{{ $t("You-have-selected") }}</span>
              <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
                <div>
                  <p v-for="(checked, index) in checkedActivities" :key="index">
                    {{ $i18n.locale == "fr" ? index + 1 + "- " + checked.attributes.descFR : checked.attributes.descEN.trim() ? index + 1 + "- " + checked.attributes.descEN : index + 1 + "- " + checked.attributes.descFR }}
                  </p>
                </div>
              </div>
            </div>
            <!-- END SECTOR FILTER -->
            <div class="mb-6 flex flex-col justify-center">
              <label for="Lastname">{{ $t("Lastname") }}</label>
              <input
                v-model="Lastname"
                type="text"
                id="Lastname"
                name="Lastname"
                required
                class="form-control block px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="companyName">{{ $t("companyName") + "*" }}</label>
              <input
                v-model="companyName"
                type="text"
                id="companyName"
                name="companyName"
                required
                class="form-control block px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="address">{{ $t("address") + "*" }}</label>
              <textarea
                name="address"
                id="address"
                v-model="address"
                class="form-control resize-none block px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              ></textarea>
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="country">{{ $t("country") + "*" }}</label>
              <input
                v-model="country"
                type="text"
                id="country"
                name="country"
                required
                class="form-control block px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <div class="mb-6 flex flex-col justify-center">
              <label for="email">{{ $t("Email") }}</label>
              <input
                v-model="email"
                type="text"
                id="email"
                name="email"
                required
                class="form-control block px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              />
            </div>
            <span>{{ $t("* Required fields") }}</span>
            <div class="mt-12">
              <button @click="submitForm" class="px-6 py-2 border-2 border-green-700 text-green-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                {{ $t("Send") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  data() {
    return {
      email: "",
      Lastname: "",
      companyName: "",
      address: "",
      country: "",
      inputActivity: "",
      inputCode: "",
      isActivityOpen: false,
      activitys: [],
      idActivity: "",
      checkedActivities: [],
    };
  },

  async mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      localStorage.setItem("email", this.$route.query.email);
    } else this.email = localStorage.getItem("email");
    this.Lastname = localStorage.getItem("lastName");
    this.companyName = localStorage.getItem("companyName");
    this.address = localStorage.getItem("address");
    this.country = localStorage.getItem("country");
  },

  methods: {
    deleteNumber(string) {
      return string.replace(/[0-9]/g, "");
    },
    setInputActivity(value) {
      this.inputActivity = this.$i18n.locale == "fr" ? value.descFR.trim() : value.descEN.trim() ? value.descEN.trim() : value.descFR.trim();
      this.isActivityOpen = false;
    },
    async submitForm() {
      localStorage.setItem("lastName", this.Lastname);
      localStorage.setItem("companyName", this.companyName);
      localStorage.setItem("email", this.email);
      localStorage.setItem("address", this.address);
      localStorage.setItem("country", this.country);
      var idItems = [];
      this.checkedActivities.forEach((item) => {
        idItems.push(item.id);
      });

      if (this.email && this.Lastname && this.companyName && this.address && this.country && idItems.length > 0) {
        axios
          .post(`${process.env.VUE_APP_STRAPI_API_URL}/api/newsletters`, {
            data: {
              email: this.email,
              last_name: this.Lastname,
              company_name: this.companyName,
              address: this.address,
              country: this.country,
              bible_materials: idItems,
            },
          })
          .then((response) => {
            if (response.status == 200) {
              this.sendMail();
            }
          })
          .catch((error) => {
            if (error.response.status == 400) {
              if (error.response.data.error.message == "This attribute must be unique") alert(this.$t("You have already registered for the newsletter"));
            } else {
              console.log(error.message);
            }
            return false;
          });
        return true;
      } else {
        alert(this.$t("please-fill-all-fields"));
      }
      // else {
      //   alert(this.$t("Please enter a valid email"));
      //   return false;
      // }
    },
    async getActivity() {
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters: {
          $and: [this.$i18n.locale == "fr" ? { descFR: { $contains: this.inputActivity.trim() } } : { descEN: { $contains: this.inputActivity.trim() } }, { code: { $contains: "00" } }],
        },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`);
        const uniqueActivity = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.descFR === value.attributes.descFR) === index;
        });
        this.activitys = uniqueActivity;
        this.isActivityOpen = true;
      } catch (error) {
        this.error = error;
      }
    },

    async sendMail() {
      var items = "";
      this.checkedActivities.forEach((item) => {
        items = items + `<li>${item.attributes.descFR.trim()}</li>`;
      });
      const text = `
      <div>
        <p>Une nouvelle inscription à la newsletter a été enregistrée.</p>
        <p>Nom: ${this.Lastname}</p>
        <p>E-mail: ${this.email}</p>
        <p>Nom de la société: ${this.companyName}</p>
        <p>Adresse: ${this.address}</p>
        <p>Pays: ${this.country}</p>
        <p>Activité(s):</p>
        <ul>
          ${items}
        </ul>
      </div>
      `;
      const data = {
        to: "contact@jacquart.com",
        subject: "Abonnement a la newsletter",
        text: text,
        from: "development@atelier.ovh",
      };
      try {
        const response = await axios.post(`${process.env.VUE_APP_STRAPI_API_URL}/api/email`, data);
        if (response) {
          this.email = "";
          this.Lastname = "";
          this.companyName = "";
          this.address = "";
          this.idActivity = "";
          this.inputActivity = "";
          this.country = "";
          this.checkedActivities = [];
          alert(this.$t("You are subscribed to the newsletter"));
          window.location.href = "/";
        }
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    checkParentModal(event) {
      let parent = event.target.parentElement;
      let found = false;
      while (parent) {
        if (parent.id === "modal") {
          found = true;
        }
        parent = parent.parentElement;
      }
      if (!found) this.isActivityOpen = false;
    },
  },
};
</script>
