<template>
  <main class="container mx-auto" @click="checkParentModal">
    <div class="flex flex-col justify-center items-center mx-auto my-5 py-4">
      <h1 class="rounded text-3xl text-center text-bold text-white bg-black p-4 mb-3">
        {{ $t("CONTACT US") }}
      </h1>
    </div>
    <div class="bg-gray-100 shadow-lg hover:shadow-2xl rounded mb-10">
      <div class="px-6 py-6">
        <div class="mx-2 my-2 md:grid md:grid-cols-3 sm:flex sm:flex-wrap sm:justify-center">
          <div class="mx-2 my-5 col-span-1">
            <div class="mb-6 flex flex-col justify-center">
              <div class="flex flex-col justify-center items-center mx-auto">
                <h3 class="rounded text-xl text-center text-bold text-white bg-black p-4 mb-3">
                  FRANCE
                </h3>
              </div>
              <ul class="list-none">
                <li class="py-1">
                  <a href="https://goo.gl/maps/ZzfwmwEVEXHrkm1L8" target="_blank"
                    class="text-gray-600 hover:text-green-600 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>33 Chemin de Cézanne, 13016 Marseille - France</a>
                </li>
                <li class="py-1">
                  <a href="mailto:contact@jacquart.com?subject=Information"
                    class="text-gray-600 hover:text-green-600 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>contact@jacquart.com</a>
                </li>
                <li class="py-1">
                  <a href="tel:+33320289428" class="text-gray-600 hover:text-green-600 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>Tel: +33 (0)3.20.28.94.28</a>
                </li>
              </ul>

              <div class="flex flex-col justify-center items-center mx-auto mt-3">
                <h3 class="rounded text-xl text-center text-bold text-white bg-black p-4 mb-3">
                  {{ $t("ITALY") }}
                </h3>
              </div>

              <ul class="list-none">
                <li class="py-1">
                  <a href="https://goo.gl/maps/ZMDSa5K5opY2CPQKA" target="_blank"
                    class="text-gray-600 hover:text-green-600 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>Via Mazzini 39 13900 Biella - ITALIA</a>
                </li>
                <li class="py-1">
                  <a href="mailto:contact@jacquart.com?subject=Information"
                    class="text-gray-600 hover:text-green-600 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>info@jacquart.it</a>
                </li>
                <li class="py-1">
                  <a href="tel:+33320289428" class="text-gray-600 hover:text-green-600 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>+39 (0)15.35.21.56</a>
                </li>
              </ul>
            </div>
          </div>
          <FormContact ref="childComponent" class="mx-2 my-5 col-span-2" id="modal" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import FormContact from "@/components/FormContact";
export default {
  components: {
    FormContact,
  },
  methods: {
    checkParentModal(event) {
      let parent = event.target.parentElement;
      let found = false
      while (parent) {
        if (parent.id === 'modal') {
          found = true
        }
        parent = parent.parentElement;
      }
      if (!found) this.$refs.childComponent.hideModalFromParent();
    }
  }
};
</script>
