<template>
  <div class="bg-white" v-if="ready && auctions">
    <embed
      v-if="auctions.pdf_fr.data"
      :src="$i18n.locale == 'fr' ? auctions.pdf_fr.data.attributes.url + '#toolbar=0&zoom=' + windowWidth : auctions.pdf_en.data.attributes.url + '#toolbar=0&zoom=' + windowWidth"
      width="100%"
      height="1000"
      type="application/pdf"
    />
    <div class="md:w-3/5 m-auto flex flex-wrap justify-center">
      <img v-for="(opportunity, index) in auctions.images.data" :key="index" class="w-60 h-auto p-2" :src="opportunity.attributes.url" alt="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      auctions: "",
      windowWidth: 0,
      ready: false,
      baseUrl: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  mounted() {
    this.getMachine();
    this.windowWidth = window.innerWidth * (8 / 100);
    // window.addEventListener("resize", this.onResize);
  },
  methods: {
    async getMachine() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/auctions/${this.$route.params.id}?populate=*`);
        this.auctions = response.data.data.attributes;
        this.ready = true;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth * (10 / 100);
      console.log(this.windowWidth);
    },
  },
};
</script>
