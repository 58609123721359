<template>
  <main class="container flex-row mx-auto mt-10 mb-10">
    <div class="w-full">
      <div class="flex flex-col items-center">
        <!-- card goes here -->
        <div v-if="opportunities.length > 0" class="flex flex-wrap justify-start -m-4 space-x-0 w-full">
          <div class="xl:w-1/3 p-4" v-for="opportunity in opportunities" :key="'m' + opportunity.id">
            <a :href="'opportunity/' + opportunity.id">
              <div class="bg-gray-100 p-6 rounded-lg">
                <img
                  class="lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72 rounded w-full object-cover object-center mb-6"
                  :src="opportunity.attributes.images.data ? opportunity.attributes.images.data[0].attributes.url : '/default.jpg'"
                  alt=""
                />
                <h3 class="tracking-widest font-medium text-center">
                  {{ $i18n.locale == "fr" ? opportunity.attributes.title_fr : opportunity.attributes.title_en }}
                </h3>
              </div>
            </a>
          </div>
        </div>
        <div v-else-if="load" class="flex justify-center text-center flex-wrap px-10 py-5">
          <h1 class="bg-black text-white p-2 text-3xl rounded">
            {{ $t("Loading") }}
          </h1>
        </div>
        <div v-else class="flex justify-center text-center flex-wrap px-10 py-5">
          <h1 class="bg-black text-white p-2 text-3xl rounded">
            {{ $t("Aucun résultat trouvé") }}
          </h1>
        </div>
        <ul v-if="opportunities.length > 0" class="flex mt-2 justify-center">
          <li
            class="cursor-pointer bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 ml-0 rounded-l-lg leading-tight py-2 px-3"
            @click="pagination.page > 1 ? pagination.page-- && getOpportunities() : null"
          >
            &larr;
          </li>
          <span v-for="index in paginationCount" :key="index">
            <li
              v-if="index + pagination.page - 3 >= 1 && index + pagination.page - 3 <= pagination.pageCount"
              class="cursor-pointer border border-gray-300 leading-tight py-2 px-3"
              :style="pagination.page === index + pagination.page - 3 ? 'background:#000;color:#FFF' : 'background:#FFF;color:#000'"
              @click="(pagination.page = index + pagination.page - 3), getOpportunities()"
            >
              {{ index + pagination.page - 3 }}
            </li>
          </span>
          <li
            class="cursor-pointer bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 ml-0 rounded-r-lg leading-tight py-2 px-3"
            @click="pagination.page < pagination.pageCount ? pagination.page++ && getOpportunities() : null"
          >
            &rarr;
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
// import Card from "@/components/Card";
import axios from "axios";
import qs from "qs";
export default {
  // name: "All-machine",

  components: {
    // Card,
  },
  data() {
    return {
      opportunities: [],
      pagination: {
        page: 0,
        pageCount: 0,
        pageSize: 0,
      },
      paginationCount: 5,
      load: true,
    };
  },
  computed() {},
  mounted() {
    this.getOpportunities();
  },
  methods: {
    async getOpportunities() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.load = true;
      this.opportunities = [];
      let query = qs.stringify({
        populate: "*",
        pagination: { page: this.pagination.page, pageSize: 20 },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/opportunities?${query}`);
        this.opportunities = response.data.data;
        this.pagination = response.data.meta.pagination;
        this.load = false;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
  },
};
</script>
