<template>
  <div @click="checkParentModal">
    <div>
      <div class="px-1" id="modal">
        <div class="">
          <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
            <input :placeholder="$t('Select-activities')" v-model="inputActivity" @focus="getActivity" @input="getActivity" class="p-1 px-2 appearance-none outline-none w-full text-gray-800" />
            <div>
              <button @click="!isActivityOpen ? getActivity() : (isActivityOpen = false)" id="toggle" class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none">
                <svg v-if="isActivityOpen" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
                <svg v-if="!isActivityOpen" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div v-if="isActivityOpen" class="shadow top-100 z-40 lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
          <div class="flex flex-col w-full">
            <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100" style="" v-for="(activity, index) in activitys" :key="'activity' + index">
              <div class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                <div class="w-full items-center flex">
                  <input type="checkbox" class="mr-4" :id="activity.id" :value="activity" v-model="checkedActivities" />
                  <label :for="activity.id">{{ $i18n.locale == "fr" ? activity.attributes.descFR : activity.attributes.descEN.trim() ? activity.attributes.descEN : activity.attributes.descFR }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="checkedActivities.length > 0">
        <span>{{ $t("You-have-selected") }}</span>
        <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
          <div>
            <p v-for="(checked, index) in checkedActivities" :key="index">
              {{ $i18n.locale == "fr" ? index + 1 + "- " + checked.attributes.descFR : checked.attributes.descEN.trim() ? index + 1 + "- " + checked.attributes.descEN : index + 1 + "- " + checked.attributes.descFR }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div class="mb-6 flex flex-col ml-1">
        <label for="lastname">{{ $t("Lastname") }}</label>
        <input
          v-model="lastname"
          type="text"
          id="lastname"
          name="lastname"
          class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
          required
        />
      </div>
      <div class="mb-6 flex flex-col mr-2">
        <label for="companyName">{{ $t("companyName") + "*" }}</label>
        <input
          v-model="companyName"
          type="text"
          id="companyName"
          name="companyName"
          class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
          required
        />
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div class="mb-6 flex flex-col ml-1">
        <label for="address">{{ $t("address") + "*" }}</label>
        <input
          v-model="address"
          type="text"
          id="address"
          name="address"
          class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
          required
        />
      </div>
      <div class="mb-6 flex flex-col mr-2">
        <label for="country">{{ $t("country") + "*" }}</label>
        <input
          v-model="country"
          type="text"
          id="country"
          name="country"
          class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
          required
        />
      </div>
    </div>
    <div class="mb-6 flex flex-col justify-center">
      <label for="email">{{ $t("Email") }}</label>
      <input
        v-model="email"
        type="text"
        id="email"
        name="email"
        class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
        required
      />
    </div>
    <div class="mb-6 flex flex-col justify-center">
      <label for="description">{{ $t("Message") + "*" }}</label>
      <textarea
        v-model="description"
        type="text"
        id="description"
        name="description"
        class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
        rows="4"
        required
      />
    </div>
    <span>{{ $t("* Required fields") }}</span>
    <div class="mt-10">
      <button @click.prevent="handle" class="px-6 py-2 border-2 border-green-700 text-green-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
        {{ $t("Send") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "FormContact",
  props: {
    code: { type: String, default: "xxxxx" },
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      description: "",
      inputActivity: "",
      companyName: "",
      address: "",
      country: "",
      inputCode: "",
      isActivityOpen: false,
      activitys: [],
      idActivity: "",
      checkedActivities: [],
    };
  },

  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      localStorage.setItem("email", this.$route.query.email);
    } else this.email = localStorage.getItem("email");
    this.lastname = localStorage.getItem("lastName");
    this.companyName = localStorage.getItem("companyName");
    this.address = localStorage.getItem("address");
    this.country = localStorage.getItem("country");
  },
  methods: {
    async handle() {
      // stockage des données client dans le localStorage
      localStorage.setItem("lastName", this.lastname);
      localStorage.setItem("companyName", this.companyName);
      localStorage.setItem("email", this.email);
      localStorage.setItem("address", this.address);
      localStorage.setItem("country", this.country);

      // Validation
      const mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.lastname) {
        alert(this.$t("Enter a lastname"));
        return false;
      } else if (!this.email) {
        alert(this.$t("Enter a email"));
        return false;
      } else if (!this.description) {
        alert(this.$t("Enter a description"));
        return false;
        // requete d'envoi du mail / vérification du mail
      } else if (!this.companyName || !this.address || !this.country) {
        alert(this.$t("please-fill-all-fields"));
        return false;
        // requete d'envoi du mail / vérification du mail
      } else if (this.email.match(mailFormat)) {
        var items = "";
        this.checkedActivities.forEach((item) => {
          items = items + `<li>${item.attributes.descFR.trim()}</li>`;
        });
        const response = await fetch(`${process.env.VUE_APP_STRAPI_API_URL}/api/email`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            to: "contact@jacquart.com",
            from: "development@atelier.ovh",
            subject: "Demande de contact",
            html: `<div>
                    <p>Une nouvelle demande de contact a été reçue</p>
                    <p>Nom: ${this.lastname}</p>
                    <p>E-mail: ${this.email}</p>
                    <p>Nom de la société: ${this.companyName}</p>
                    <p>Adresse: ${this.address}</p>
                    <p>Pays: ${this.country}</p>
                    <p>Activité(s):</p>
                    <ul>
                      ${items}
                    </ul>
                  </div>`,
          }),
        });
        if (response.status == 200) {
          alert(this.$t("Form send successfully"));
          window.location.href = "/";
          return true;
        } else {
          alert(this.$t("Something went wrong, please try again later"));
        }
        const datas = await response.json();
        console.log(datas);

        // éfface la description du formulaire
        this.description = "";
      } else {
        alert(this.$t("Enter a valid email"));
        return false;
      }
    },
    async getActivity() {
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters: {
          $and: [this.$i18n.locale == "fr" ? { descFR: { $contains: this.inputActivity.trim() } } : { descEN: { $contains: this.inputActivity.trim() } }, { code: { $contains: "00" } }],
        },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`);
        const uniqueActivity = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.descFR === value.attributes.descFR) === index;
        });
        this.activitys = uniqueActivity;
        this.isActivityOpen = true;
      } catch (error) {
        this.error = error;
      }
    },
    hideModalFromParent() {
      this.isActivityOpen = false;
    },
    checkParentModal(event) {
      let parent = event.target.parentElement;
      let found = false;
      while (parent) {
        if (parent.id === "modal") {
          found = true;
        }
        parent = parent.parentElement;
      }
      if (!found) this.isActivityOpen = false;
    },
  },
};
</script>
