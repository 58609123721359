<template>
  <div class="bg-white px-8">
    <div class="">
      <div class="firstBackground">
        <div class="flex justify-center items-center pt-80 pb-80">
          <img src="@/assets/logo.png" alt="" class="w-full h-56 md:max-auto sm:max-auto" />
        </div>
      </div>
    </div>
    <div class="p-3 space-y-4 flex flex-col justify-center items-center flex-wrap">
      <div class="flex justify-center w-5/6 pt-10">
        <h4 class="text-4xl text-black font-bold bg-gray-200 flex justify-center p-3">
          {{ $t("100 years of experience at your service") }}
        </h4>
      </div>
      <div class="lg:w-1/2 flex items-center justify-center">
        <p class="flex justify-center text-justify p-5 text-lg">
          {{
            $t(
              "JACQUART company, already handed down from father to son for four generations, is justly proud of its past. Their experience as mill owners gives them the necessary expertise to act as highly qualified intermediaries between companies wishing to sell some individual machines or even their entire factory, and those who wish to buy second-hand textile equipment. Their competence is well-known and recognised by all professionals in the textile industry (mill owners, weavers, dyers, finishers) and those in sales (trade unions, auctioneers, financial institutions...)."
            )
          }}
        </p>
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-center items-center md:items-start md:space-x-8 pb-20 md:max-auto sm:max-auto">
      <div class="w-full md:w-1/2 bg-no-repeat bg-cover" :style="'background-image: url(/history2.jpeg);height:540px'"></div>
      <div class="w-full md:w-1/2 space-y-5">
        <div class="bg-cardColor flex flex-col items-center">
          <div class="pt-4 pb-4">
            <h4 class="text-white bg-black w-44 flex justify-center p-2">
              {{ $t("TRADE") }}
            </h4>
          </div>

          <p class="p-3 items-center">
            {{
              $t(
                "Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance. This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers."
              )
            }}
          </p>
        </div>
        <div class="bg-cardColor flex flex-col items-center">
          <div class="pt-4 pb-4">
            <h4 class="text-white bg-black w-44 flex justify-center p-2">
              {{ $t("BROKERAGE") }}
            </h4>
          </div>

          <p class="p-3 items-center">
            {{
              $t(
                "In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance. For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily."
              )
            }}
          </p>
        </div>
        <div class="bg-cardColor flex flex-col items-center">
          <div class="pt-4 pb-4">
            <h4 class="text-white bg-black w-44 flex justify-center p-2">
              {{ $t("APPRAISALS") }}
            </h4>
          </div>

          <p class="p-3 items-center">
            {{
              $t(
                "Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned. Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal."
              )
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row-reverse justify-center items-center md:items-start pb-20 md:max-auto sm:max-auto">
      <div class="w-full md:w-1/2 md:ml-8 bg-no-repeat bg-cover" :style="'background-image: url(/history3.jpeg);height:540px'"></div>
      <div class="w-full md:w-1/2 space-y-5">
        <div class="bg-cardColor flex flex-col items-center">
          <div class="pt-4 pb-4">
            <h4 class="text-white bg-black w-44 flex justify-center p-2">
              {{ $t("Purchases - Sales") }}
            </h4>
          </div>

          <p class="p-3 items-center">
            {{
              $t(
                "Our team is always ready to inspect equipment for sale on site, wherever its location. Visitors from all over the world take an interest in our second-hand equipment. Our aims in serving our clients are many : Canvassing, going to see equipment to be sold on site, wherever its location, making appraisals, replying to demands, meeting potential buyers…then supervising dimdantling and reassemble of equipment, transport, customs formalities etc... This we do with great passion and always with the essential philosophy of selling in mind : of serving our customers to the maximum."
              )
            }}
          </p>
        </div>
        <div class="bg-cardColor flex flex-col items-center">
          <div class="pt-4 pb-4">
            <h4 class="text-white bg-black w-44 flex justify-center p-2">
              {{ $t("Implantation") }}
            </h4>
          </div>

          <p class="p-3 items-center">
            {{
              $t(
                "Our team is always ready to inspect equipment for sale on site, wherever its location. Visitors from all over the world take an interest in our second-hand equipment. Our aims in serving our clients are many : Canvassing, going to see equipment to be sold on site, wherever its location, making appraisals, replying to demands, meeting potential buyers…then supervising dimdantling and reassemble of equipment, transport, customs formalities etc... This we do with great passion and always with the essential philosophy of selling in mind : of serving our customers to the maximum."
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script></script>

<style>
.firstBackground {
  background: url("../assets/history.jpg") center center/ cover no-repeat;
}
</style>
