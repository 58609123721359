<template>
  <div @click="checkParentModal">
    <main class="container mx-auto mt-10 mb-10">
      <div class="flex flex-col md:flex-row space-x-2">
        <!-- filtre -->
        <div class="flex justify-start relative min-w-max">
          <div class="w-full mb-8 md:mb-0 bg-gray-100 md:w-64">
            <div class="flex justify-center items-center mx-auto p-4 flex-wrap">
              <h4 class="text-center text-bold text-white bg-black p-2 rounded shadow-md overflow-hidden">
                {{ $t("FILTERS") }}
              </h4>
            </div>
            <div class="bg-gray-100 py-2">
              <!-- START SEARCH FILTER -->
              <div class="px-1">
                <div class="">
                  <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
                    <input
                      :placeholder="$t('Keyword')"
                      v-model="inputSearch"
                      @input="
                        pagination.page = 0;
                        getMachines();
                      "
                      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                    />
                    <div>
                      <button
                        @click="
                          inputSearch = '';
                          pagination.page = 0;
                          getMachines();
                        "
                        class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-x w-4 h-4"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END SEARCH FILTER -->
              <!-- START activity FILTER -->
              <div class="px-1" id="modal">
                <div class="">
                  <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
                    <input :placeholder="$t('Activity')" v-model="inputActivity" @focus="getActivity" @input="getActivity" class="p-1 px-2 appearance-none outline-none w-full text-gray-800" />
                    <div>
                      <button
                        @click="
                          inputActivity = '';
                          inputCode = '';
                          inputSubCategory = '';
                          inputSubCode = '';
                          pagination.page = 0;
                          getMachines();
                        "
                        class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-x w-4 h-4"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="isActivityOpen" class="shadow top-100 z-40 w-full lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
                  <div class="flex flex-col w-full">
                    <div
                      class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                      style=""
                      v-for="(activity, index) in activitys"
                      :key="'activity' + index"
                      @click="
                        setInputActivity(activity.attributes);
                        inputCode = deleteNumber(activity.attributes.code.trim());
                        pagination.page = 0;
                        getMachines();
                      "
                    >
                      <div class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                        <div class="w-full items-center flex">
                          <div class="mx-2 leading-6">
                            {{ $i18n.locale == "fr" ? activity.attributes.descFR : activity.attributes.descEN.trim() ? activity.attributes.descEN : activity.attributes.descFR }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END activity FILTER -->

              <div class="px-1" id="modal">
                <div class="">
                  <div class="my-2 bg-white p-1 flex border border-gray-200 rounded" :style="inputActivity && inputCode ? 'opacity: 1' : 'opacity: 0.4'">
                    <input
                      :disabled="inputActivity && inputCode ? false : true"
                      :placeholder="$t('subActivity')"
                      v-model="inputSubCategory"
                      @focus="getSubCategory"
                      @input="getSubCategory"
                      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                    />
                    <div>
                      <button
                        @click="
                          inputSubCategory = '';
                          inputSubCode = '';
                          pagination.page = 0;
                          getMachines();
                        "
                        class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-x w-4 h-4"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="isSubCategoryOpen" class="shadow top-100 z-40 w-full lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
                  <div class="flex flex-col w-full">
                    <div
                      class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                      style=""
                      v-for="(subCategory, index) in subCategories"
                      :key="'subCategory' + index"
                      @click="
                        setInputSubCategory(subCategory.attributes);
                        inputSubCode = subCategory.attributes.code.trim();
                        pagination.page = 0;
                        getMachines();
                      "
                    >
                      <div
                        v-if="subCategory.attributes.code.trim() != inputCode + '00'"
                        class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600"
                      >
                        <div class="w-full items-center flex">
                          <div class="mx-2 leading-6">
                            {{ $i18n.locale == "fr" ? subCategory.attributes.descFR : subCategory.attributes.descEN.trim() ? subCategory.attributes.descEN : subCategory.attributes.descFR }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END SUB CATEGORY FILTER -->
              <div class="mb-6 px-1 grid justify-items-center">
                <label for="">{{ $t("After (Year)") }}</label>
                <input
                  v-model="inputyear"
                  @input="
                    pagination.page = 0;
                    getMachines();
                  "
                  name="constructionOld"
                  type="number"
                  :placeholder="$t('Year of manufacture')"
                  class="form-control block w-full md:w-5/6 px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- card machine -->
        <div class="w-full">
          <div class="flex flex-col items-center">
            <!-- card goes here -->
            <div v-if="machines.length > 0" class="flex flex-wrap justify-center -m-4 space-x-0 w-full">
              <div class="xl:w-1/3 p-4" v-for="machine in machines" :key="'m' + machine.id">
                <a :href="'machine/' + machine.id">
                  <div class="bg-gray-100 p-6 rounded-lg">
                    <img
                      class="lg:h-60 xl:h-56 md:h-64 sm:h-72 xs:h-72 h-72 rounded w-full object-cover object-center mb-6"
                      :src="
                        machine.attributes.images.data
                          ? machine.attributes.images.data.filter((img) => {
                              return img.attributes.caption === 'true';
                            })[0]?.attributes.url || '/default.jpg'
                          : '/default.jpg'
                      "
                      :alt="'Ref: ' + machine.attributes.bible_material.data.attributes.code.trim() + '-' + machine.attributes.Code"
                    />
                    <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
                      {{ machine.attributes.contructionOld > 0 ? machine.attributes.contructionOld : " " }}
                    </h3>
                    <h3 class="tracking-widest text-xs font-medium title-font mb-4">Ref: {{ machine.attributes.bible_material.data.attributes.code.trim() + "-" + machine.attributes.Code }}</h3>
                    <h2 class="text-lg text-gray-900 font-medium title-font">
                      {{ machine.attributes.Brand }}
                    </h2>
                    <p
                      v-if="($i18n.locale == 'fr' && machine.attributes.descriptionFR?.trim()) || ($i18n.locale == 'en' && machine.attributes.decriptionEN?.trim())"
                      class="leading-relaxed text-base h-16"
                    >
                      {{ $i18n.locale == "fr" ? machine.attributes.descriptionFR?.slice(0, 80) : machine.attributes.decriptionEN?.slice(0, 80) }}
                      {{ $i18n.locale == "fr" && machine.attributes.descriptionFR?.length > 80 ? "..." : $i18n.locale == "en" && machine.attributes.decriptionEN?.length > 80 ? "..." : "" }}
                    </p>
                    <p v-else class="leading-relaxed text-base h-16">
                      {{ $t("description_not_available") }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div v-else-if="load" class="flex justify-center text-center flex-wrap px-10 py-5">
              <h1 class="bg-black text-white p-2 text-3xl rounded">
                {{ $t("Loading") }}
              </h1>
            </div>
            <div v-else class="flex justify-center text-center flex-wrap px-10 py-5">
              <h1 class="bg-black text-white p-2 text-3xl rounded">
                {{ $t("Aucun résultat trouvé") }}
              </h1>
            </div>
            <ul v-if="machines.length > 0" class="flex mt-2 justify-end">
              <li
                class="cursor-pointer bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 ml-0 rounded-l-lg leading-tight py-2 px-3"
                @click="pagination.page > 1 ? pagination.page-- && getMachines(true) : null"
              >
                &larr;
              </li>
              <span v-for="index in paginationCount" :key="index">
                <li
                  v-if="index + pagination.page - 3 >= 1 && index + pagination.page - 3 <= pagination.pageCount"
                  class="cursor-pointer border border-gray-300 leading-tight py-2 px-3"
                  :style="pagination.page === index + pagination.page - 3 ? 'background:#000;color:#FFF' : 'background:#FFF;color:#000'"
                  @click="(pagination.page = index + pagination.page - 3), getMachines(true)"
                >
                  {{ index + pagination.page - 3 }}
                </li>
              </span>
              <li
                class="cursor-pointer bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 ml-0 rounded-r-lg leading-tight py-2 px-3"
                @click="pagination.page < pagination.pageCount ? pagination.page++ && getMachines(true) : null"
              >
                &rarr;
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
// import Card from "@/components/Card";
import axios from "axios";
import qs from "qs";
export default {
  // name: "All-machine",

  components: {
    // Card,
  },
  data() {
    return {
      inputSearch: "",
      inputActivity: "",
      inputSubCategory: "",
      inputCode: "",
      inputSubCode: "",
      inputBrand: "",
      inputyear: "",
      isActivityOpen: false,
      isSubCategoryOpen: false,
      isBrandOpen: false,
      machines: [],
      activitys: [],
      subCategories: [],
      brands: [],
      pagination: {
        page: 0,
        pageCount: 0,
        pageSize: 0,
      },
      paginationCount: 5,
      // query: null,
      search: "",
      constructionOld: "",
      selectedBrand: "",
      // activitys: [],
      // categories: [],
      selectedActivity: "",
      selectedCategorie: "",
      load: true,
      BaseUrl: process.env.VUE_APP_STRAPI_API_URL,
      navig: "",
      getQuery: this.$route.query,
      brand: "",
      categories: "",
      activity: "",
      // query: "",
    };
  },
  computed() {
    // console: () => console
  },
  mounted() {
    if (this.$route.query.search) this.inputSearch = this.$route.query.search;
    if (this.$route.query.activity) this.inputActivity = this.$route.query.activity;
    if (this.$route.query.subCategory) this.inputSubCategory = this.$route.query.subCategory;
    if (this.$route.query.code) this.inputCode = this.$route.query.code;
    if (this.$route.query.subCode) this.inputSubCode = this.$route.query.subCode;
    if (this.$route.query.brand) this.inputBrand = this.$route.query.brand;
    if (this.$route.query.year) this.inputyear = this.$route.query.year;
    if (this.$route.query.page) this.pagination.page = this.$route.query.page;

    this.getMachines();
  },
  watch: {},
  methods: {
    deleteNumber(string) {
      return string.replace(/[0-9]/g, "");
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async getMachines(goTop) {
      this.load = true;
      this.machines = [];
      this.isBrandOpen = false;
      this.isActivityOpen = false;
      this.isSubCategoryOpen = false;

      // SET PARAMS
      this.$router.push({
        query: {
          code: this.inputCode,
          subCode: this.inputSubCode,
          activity: this.inputActivity,
          subCategory: this.inputSubCategory,
          search: this.inputSearch,
          brand: this.inputBrand,
          year: this.inputyear,
          page: this.pagination.page,
        },
      });

      let query = qs.stringify({
        sort: "validDate:desc",
        populate: ["images", "bible_material"],
        pagination: { page: this.pagination.page, pageSize: 30 },
        filters: {
          $and: [
            { Status: { $eq: "SELLING" } },
            { website: { $eq: 1 } },
            this.$i18n.locale == "fr" ? { descriptionFR: { $containsi: this.inputSearch.trim() } } : { decriptionEN: { $containsi: this.inputSearch.trim() } },
            { bible_material: { code: { $startsWith: this.inputSubCode ? this.inputSubCode : this.inputCode } } },
            { Brand: { $containsi: this.inputBrand } },
            { contructionOld: { $gte: this.inputyear } },
          ],
        },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches?${query}`);
        this.load = false;
        // const uniqueActivity = response.data.data.filter((machine) => machine.attributes.images.data != null);
        this.machines = response.data.data;
        this.pagination = response.data.meta.pagination;
        goTop ? this.scrollToTop() : null;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },

    async getActivity() {
      this.isBrandOpen = false;
      this.isSubCategoryOpen = false;
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters:
          this.$i18n.locale == "fr" ? { descFR: { $contains: this.inputActivity.trim() }, code: { $contains: "00" } } : { descEN: { $contains: this.inputActivity.trim() }, code: { $contains: "00" } },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`);
        const uniqueActivity = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.descFR === value.attributes.descFR) === index;
        });
        this.activitys = uniqueActivity;
        this.isActivityOpen = true;
      } catch (error) {
        this.error = error;
      }
    },

    async getSubCategory() {
      this.isBrandOpen = false;
      this.isActivityOpen = false;
      this.isSubCategoryOpen = true;
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters: { descFR: { $contains: this.inputSubCategory.trim() }, code: { $startsWith: this.inputCode } },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`);
        const uniqueSubCategory = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.descFR === value.attributes.descFR) === index;
        });
        this.subCategories = uniqueSubCategory;
      } catch (error) {
        this.error = error;
      }
    },

    async getBrands() {
      this.isActivityOpen = false;
      this.isSubCategoryOpen = false;
      this.isBrandOpen = true;
      let query = qs.stringify({
        fields: "Brand",
        sort: "Brand:asc",
        filters: {
          Brand: { $contains: this.inputBrand.trim() },
          Status: { $eq: "SELLING" },
        },
      });

      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches?${query}`);
        const uniqueBrand = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.Brand === value.attributes.Brand) === index;
        });
        this.brands = uniqueBrand;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },

    setInputActivity(value) {
      this.inputActivity = this.$i18n.locale == "fr" ? value.descFR.trim() : value.descEN.trim() ? value.descEN.trim() : value.descFR.trim();
    },
    setInputSubCategory(value) {
      this.inputSubCategory = this.$i18n.locale == "fr" ? value.descFR.trim() : value.descEN.trim() ? value.descEN.trim() : value.descFR.trim();
    },
    checkParentModal(event) {
      let parent = event.target.parentElement;
      let found = false;
      while (parent) {
        if (parent.id === "modal") {
          console.log("ok");
          found = true;
        }
        parent = parent.parentElement;
      }
      if (!found) {
        (this.isActivityOpen = false), (this.isBrandOpen = false), (this.isSubCategoryOpen = false);
      }
    },
  },
};
</script>
