<template>
  <main class="p-10 container mx-auto flex justify-between" v-if="!load">
    <div class="w-full grid justify-items-center md:flex md:flex-row justify-center sm:grid">
      <div class="md:w-2/3 md:pr-10 w-full">
        <Slider v-if="machine.images.data?.filter((img) => img.attributes.caption === 'true').length > 0" :imgs="machine.images.data?.filter((img) => img.attributes.caption === 'true')" />

        <div v-else>{{ $t("no-pictures") }}</div>
      </div>
      <div class="md:w-1/3 w-full">
        <div class="flex justify-center items-center mx-auto p-4 flex-wrap" v-if="machine.Type">
          <h4 class="text-3xl text-center text-bold text-white bg-black p-4 rounded shadow-md overflow-hidden">{{ $t("Type : ") }}{{ machine.Type }}</h4>
        </div>

        <hr />
        <div class="p-4 space-y-2">
          <div class="flex bg-gray-200 p-2">
            <h4 class="text-2xl text-extrabold text-black w-36">{{ $t("Ref :") }}</h4>
            <p class="text-1xl">{{ machine.bible_material.data.attributes.code.trim() + "-" + machine.Code }}</p>
          </div>
          <div class="flex bg-gray-200 p-2">
            <h4 class="text-2xl text-extrabold text-black w-36 flex-shrink-0">{{ $t("Brand :") }}</h4>
            <p class="text-1xl">{{ machine.Brand }}</p>
          </div>
          <div class="flex bg-gray-200 p-2">
            <h4 class="text-2xl text-extrabold text-black w-36">{{ $t("Year :") }}</h4>
            <p class="text-1xl">
              {{ machine.contructionOld }}
            </p>
          </div>
        </div>
        <hr />
        <div class="space-y-2">
          <h4 class="text-3xl text-extrabold text-black">{{ $t("Specs :") }}</h4>
          <div v-if="($i18n.locale == 'fr' && machine.descriptionFR?.trim()) || ($i18n.locale == 'en' && machine.decriptionEN?.trim())" class="text-1xl bg-gray-200 p-3 rounded">
            <p v-html="formattedText($i18n.locale == 'fr' ? machine.descriptionFR : machine.decriptionEN)"></p>
            <!-- {{ $i18n.locale == "fr" ? machine.descriptionFR : machine.decriptionEN }} -->
            <!-- {{ $i18n.locale == "fr" && machine.descriptionFR?.length > 80 ? "..." : $i18n.locale == "en" && machine.decriptionEN?.length > 80 ? "..." : "" }} -->
          </div>
          <p v-else class="leading-relaxed text-base h-16">
            {{ $t("description_not_available") }}
          </p>
        </div>
        <hr class="my-4" />
        <button class="flex space-x-2 bg-black text-white font-semibold p-2 rounded" @click="showForm = true">
          <p>{{ $t("interested") }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-thumbs-up"
          >
            <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
          </svg>
        </button>
      </div>
    </div>
    <!-- interested form -->
    <div v-if="showForm" class="fixed w-full h-full bg-black bg-opacity-50 top-0 left-0 z-10 flex text-center p-2">
      <div class="bg-white p-12 w-full sm:w-2/3 lg:w-1/2 xl:1/3 m-auto relative">
        <div class="grid grid-cols-2">
          <div class="mb-6 flex flex-col mr-1">
            <label for="lastname">{{ $t("Lastname") }}</label>
            <input
              v-model="lastname"
              type="text"
              id="lastname"
              name="lastname"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              required
            />
          </div>
          <div class="mb-6 flex flex-col ml-1">
            <label for="companyName">{{ $t("companyName") + "*" }}</label>
            <input
              v-model="companyname"
              type="text"
              id="companyName"
              name="companyName"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              required
            />
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div class="mb-6 flex flex-col mr-1">
            <label for="email">{{ $t("Email") }}</label>
            <input
              v-model="email"
              type="text"
              id="email"
              name="email"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              required
            />
          </div>
          <div class="mb-6 flex flex-col mr-1">
            <label for="country">{{ $t("country") + "*" }}</label>
            <input
              v-model="country"
              type="text"
              id="country"
              name="country"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
              required
            />
          </div>
        </div>
        <div class="mb-6 flex flex-col mr-1">
          <label for="address">{{ $t("address") + "*" }}</label>
          <input
            v-model="address"
            type="text"
            id="address"
            name="address"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
            required
          />
        </div>
        <div class="mb-6 flex flex-col justify-center">
          <label for="description">{{ $t("Message") }}</label>
          <textarea
            v-model="description"
            type="text"
            id="description"
            name="description"
            class="resize-none form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
            rows="4"
            required
          />
        </div>
        <div class="mt-10">
          <button
            @click="sendInterested()"
            class="px-6 py-2 border-2 border-green-700 text-green-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            {{ $t("Send") }}
          </button>
        </div>
        <button class="absolute -top-2 -right-2 bg-black rounded-full w-8 h-8 text-white flex justify-center items-center" @click="showForm = flase">X</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import qs from "qs";
import Slider from "@/components/Slider.vue";
// import FormContact from "@/components/FormContact.vue";
export default {
  components: {
    Slider,
    // FormContact,
  },
  data() {
    return {
      machine: "",
      load: true,
      lastname: "",
      companyname: "",
      country: "",
      address: "",
      email: "",
      showForm: false,
      description: "",
    };
  },

  mounted() {
    this.lastname = localStorage.getItem("lastName");
    this.companyname = localStorage.getItem("companyName");
    this.email = localStorage.getItem("email");
    this.country = localStorage.getItem("country");
    this.address = localStorage.getItem("address");
    this.getMachine();
  },
  methods: {
    async getMachine() {
      this.load = true;
      let query = qs.stringify({
        populate: ["images", "bible_material"],
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches/${this.$route.params.id}?${query}`);
        this.load = false;
        this.machine = response.data.data.attributes;
        console.log(this.machine);
        this.description =
          this.$i18n.locale == "fr"
            ? `Bonjour, je suis intéressé(e) par votre machine portant la référence ${
                this.machine.bible_material.data.attributes.code.trim() + "-" + this.machine.Code
              }. Est-ce qu'elle est toujours disponible ? Cordialement`
            : `Hi, I'm interested in your machine with the reference ${this.machine.bible_material.data.attributes.code.trim() + "-" + this.machine.Code}. Is it still available? Sincerely`;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    async sendInterested() {
      localStorage.setItem("lastName", this.lastname);
      localStorage.setItem("companyName", this.companyname);
      localStorage.setItem("email", this.email);
      localStorage.setItem("country", this.country);
      if (this.email && this.lastname && this.companyname && this.description && this.address) {
        try {
          await axios.post(`${process.env.VUE_APP_STRAPI_API_URL}/api/interested-machines/`, {
            data: {
              email: this.email,
              last_name: this.lastname,
              company_name: this.companyname,
              country: this.country,
              machine_fiche: this.$route.params.id,
              address: this.address,
            },
          });
          this.showForm = false;
          this.sendMail();
        } catch (error) {
          alert(this.$t("send-error"));
          console.log(error);
        }
      } else {
        alert(this.$t("all-fields"));
      }
    },
    async sendMail() {
      const text = `
      <div>
        <p>Nom: ${this.lastname}</p>
        <p>E-mail: ${this.email}</p>
        <p>Nom de l'entreprise: ${this.companyname}</p>
        <p>Adresse: ${this.address}</p>
        <p>Pays: ${this.country}</p>
        <p>Code machine: ${this.machine.bible_material.data.attributes.code.trim() + "-" + this.machine.Code}</p>
        <br>
        <p>${this.description}</p>
      </div>
      `;
      const data = {
        to: "contact@jacquart.com",
        subject: "Demande d'intérêt",
        text: text,
        from: "development@atelier.ovh",
      };
      try {
        const response = await axios.post(`${process.env.VUE_APP_STRAPI_API_URL}/api/email`, data);
        if (response) {
          alert(this.$t("send-success"));
        }
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    formattedText(description) {
      return description.replace(/\r\n/g, "<br>");
    },
  },
};
</script>
